<template>
  <div v-if="content && typeof content === 'object'"
       :class="$setClasses">
    <div v-if="content.html"
         v-html="content.html"
         :class="$vClass('before-content')" />
    <estimation-simulator v-if="content['estimation-simulator']"
                          :after-content="content['estimation-simulator'].after_content
                           || $t('estimate.simulator.afterContent')"
                          :before-content="content['estimation-simulator'].before_content
                           || $t('estimate.simulator.beforeContent')"
                          :button-next="content['estimation-simulator'].button_next"
                          :force-surface-to-volume="content['estimation-simulator']
                            .force_surface_to_volume"
                          :ref-models="content['estimation-simulator'].ref_models" />
    <nxt-trustpilot v-if="content.trustpilot"
                    :color="content.trustpilot.color"
                    :height="content.trustpilot.height"
                    :stars="content.trustpilot.stars"
                    :template="content.trustpilot.template"
                    :theme="content.trustpilot.theme"
                    :title="content.trustpilot.title || $t('base.trustpilot.title')" />
  </div>
  <div v-else
       v-html="content" />
</template>

<script>
import EstimationSimulator from '@/organisms/fields/EstimationSimulator.vue';

export default {
  components: {
    EstimationSimulator,
  },
  nameClassStyle: 'panel',
  props: {
    content: [String, Object],
  },
};
</script>

<style lang="scss" scoped>
.#{$prefixName}panel {
  background-color: var(--nxt-color-athens-grayapprox);
  padding: var(--nxt-space-large);
  min-width: calc(300px + 20px);
  @include breakpoint(tablet) {
    max-width: 400px;
    min-width: calc(300px + (#{var(--nxt-space-large)} * 2));
  }

  h2.#{$prefixName}panel__title {
    font-size: var(--nxt-font-size-xlarge);
    font-weight: 200;

    &:first-of-type {
      margin-top: 0;
    }
  }

  &__description {
    margin: var(--nxt-space-large) 0;
  }

  &__list {
    margin-bottom: var(--nxt-space-xxlarge);
    font-size: var(--nxt-font-size-medium);

    &__item:not(:last-child) {
      padding-bottom: var(--nxt-space-medium);
    }
  }

  :deep(.#{$prefixName}trustpilot__title) {
    font-size: var(--nxt-font-size-xlarge);
    font-weight: 200;
    text-align: center;
  }

  :deep(.#{$prefixName}estimation-simulator__wrapper) {
    margin: 10px 10px var(--nxt-space-large);
  }
}
</style>
