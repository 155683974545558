import fieldUtils from '@nextories/vue-core-components/src/utils/helpers/fieldUtils';
import gmaps from '@nextories/vue-core-components/src/utils/helpers/gmaps';
import format from '@nextories/vue-core-components/src/utils/helpers/format';

export default {
  ...fieldUtils,
  isErrorField(field) {
    if (!field.show_text && (field.required || field.value)) {
      if (field.value === undefined || field.value === '') {
        return true;
      }
      if (field.type === 'address') {
        return field.value && (!field.value.city
          || gmaps.hasErrorAddressPostal(field.value.postal, field.value.country));
      }
      if (field.type === 'date') {
        return !Date.parse(field.value) || Date.parse(new Date()) > Date.parse(field.value);
      }
      // Check if the value is excluded
      if (!format.checkExcludeValues(field.exclude_values)) {
        return true;
      }
      return !RegExp(field.pattern)
        .test(field.value);
    }
    return false;
  },
  cleanData(steps) {
    const formData = {};
    const refValueFields = [];
    for (const step of steps) {
      step.fields.forEach((field) => {
        if (!field.no_save && (field.visibility || field.hide)) {
          if (((field.value && field.value.refValueFieldId) || field.refValueFieldId)
            && (field.hide || !field.value)) {
            refValueFields.push(field);
            // Check if 'not touched' is not set
          } else if (!field.notTouched) {
            formData[field.id] = field.value;
          }
        }
      });
    }
    for (const field of refValueFields) {
      if (formData[field.refValueFieldId]) {
        formData[field.id] = formData[field.refValueFieldId];
      }
    }
    return { formData };
  },
  checkRefField(value, refField, refFieldCondition) {
    if (refField.value !== undefined) {
      refFieldCondition = RegExp(refField.value)
        .test(value);
    } else if (typeof refField.max_length === 'number'
      && typeof refField.min_length === 'number') {
      refFieldCondition = value.length >= refField.min_length
        && value.length <= refField.max_length;
    } else if (typeof refField.max_length === 'number') {
      refFieldCondition = value.length <= refField.max_length;
    } else if (typeof refField.min_length === 'number') {
      refFieldCondition = value.length >= refField.min_length;
    } else if (typeof refField.max === 'number'
      && typeof refField.min === 'number') {
      refFieldCondition = value >= refField.min
        && value <= refField.max;
    } else if (typeof refField.max === 'number') {
      refFieldCondition = value <= refField.max;
    } else if (typeof refField.min === 'number') {
      refFieldCondition = value >= refField.min;
    }
    return !!refFieldCondition;
  },
  isValidRefFields(refFields, steps) {
    let refFieldCondition;
    for (const refField of refFields) {
      const findRefField = this.findFieldById(refField.name || refField.id, steps);
      if (findRefField) {
        refFieldCondition = this.checkRefField(findRefField.value, refField, refFieldCondition);
      }
      if (!refFieldCondition) {
        break;
      }
    }
    return refFieldCondition;
  },
};
