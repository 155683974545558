import Bugsnag from '@nextories/vue-core-components/src/utils/modules/bugsnag';
import fieldUtils from '@/utils/helpers/fieldUtils';
import i18n from '@/i18n';

function removeItem(items, key, value) {
  const itemFindIndex = items.findIndex((item) => item[key] === value);
  if (itemFindIndex !== -1) {
    items.splice(itemFindIndex, 1);
  }
  return items;
}

// Send options parameters (gclid, msclkid, utmSource, utmCampaign, utmMedium)
// to "form data"
// or send parameters (partnerId, partnerLeadId) to "param"
function setParamRequest(request, state) {
  const param = {};
  if (state.partnerId) {
    param.partner = state.partnerId;
  }
  const other = {};
  for (const item of ['gclid', 'msclkid', { value: 'partnerLeadId', notFormData: true },
    { value: 'utmSource', dataIsPriority: true }, { value: 'utmCampaign', dataIsPriority: true },
    { value: 'utmMedium', dataIsPriority: true }]) {
    const value = item.value ? item.value : item;
    const key = value.replace(/([A-Z])/g, '_$1').toLowerCase();
    if (state[value]) {
      if (item.notFormData) {
        param[key] = state[value];
      } else {
        other[key] = item.dataIsPriority && request.formData[key]
          ? request.formData[key] : state[value];
      }
    }
  }
  Object.assign(request, param);
  if (Object.entries(other).length > 0) {
    Object.assign(request.formData, other);
  }
  return request;
}

const state = {
  crmToken: '',
  projectToken: '',
  estimateLoading: false,
  meetingsSlotsLoading: false,
  onlineBookingLoading: false,
  typeMeetingLoading: false,
  partnerLoading: false,
  isDataSaving: false,
  dataSaveError: false,
  partnerError: false,
  messageError: null,
  scrollFields: false,
  steps: [],
  totalSteps: 0,
  headerConfig: {},
  currentStep: {},
  currentStepNb: 0,
  typeMeeting: null,
  meetingInfos: null,
  meetingsSlots: [],
  estimation: null,
  estimationDone: 0,
  partnerStyle: {},
  allowEstimate: null,
  meetTypeAvailable: false,
  onlineBooking: true,
  scrollYAuto: true,
  fixedHeaderHeight: 0,
  meetingDuration: 60,
  minarmRights: null,
  initialData: '',
  partnerLeadId: '',
  utmSource: '',
  utmMedium: '',
  utmCampaign: '',
  gclid: '',
  msclkid: '',
  partnerId: '',
  configId: '',
  customId: '',
};

const actions = {
  track({ state }) {
    if (state.currentStep && state.currentStep.data_analytics) {
      this.dispatch('analyticsStore/track', {
        dimensions: {
          dimension1: state.partnerId,
          dimension2: state.allowEstimate,
          dimension3: state.estimationDone,
          dimension5: state.utmSource,
          dimension6: state.utmMedium,
          dimension7: state.utmCampaign,
          user_properties: {
            PARTNER_ID: state.partnerId,
            ALLOW_ESTIMATION: state.allowEstimate,
            ESTIMATION_DONE: state.estimationDone,
            NXT_SOURCE: state.utmSource,
            NXT_MEDIUM: state.utmMedium,
            NXT_CAMPAIGN: state.utmCampaign,
          },
        },
        category: process.env.nxt.analytics.category,
        label: process.env.nxt.analytics
          .label[state.currentStep.data_analytics],
        action: state.currentStep.data_analytics,
      });
    }
  },
  getPartner({ commit }, params) {
    commit('SET_PARTNER_LOADING', true);
    return window.connector.getPartner(params.partnerId, params.configId)
      .then(async (result) => {
        commit('SET_PARTNER_CONFIG', result);
        commit('SET_PARTNER_ID', params.partnerId);
        commit('SET_CONFIG_ID', params.configId);
      })
      .catch((error) => {
        commit('SET_PARTNER_ERROR', true);
        throw new Error(error);
      })
      .finally(() => {
        commit('SET_PARTNER_LOADING', false);
      });
  },
  getEstimation({ commit }, request) {
    commit('SET_DATA_SAVING', true);
    commit('SET_DATA_SAVE_ERROR', false);
    commit('SET_ESTIMATE_LOADING', true);
    return window.connector
      .getEstimation(request)
      .then(async (result) => {
        commit('SET_ESTIMATE', { estimation: result });
        this.dispatch('partnerStore/track');
      })
      .catch((error) => {
        commit('SET_DATA_SAVE_ERROR', true);
        commit('SET_ESTIMATE', {});
        commit('SET_MESSAGE_ERROR',
          i18n.messages[i18n.locale].base.messageError.getEstimate);
        throw new Error(error);
      })
      .finally(() => {
        commit('SET_DATA_SAVING', false);
        commit('SET_ESTIMATE_LOADING', false);
      });
  },
  getEstimate({ commit }) {
    commit('SET_ESTIMATE_LOADING', true);
    return window.connector
      .getEstimate()
      .then(async (result) => {
        commit('SET_ESTIMATE', result);
        this.dispatch('partnerStore/track');
      })
      .catch((error) => {
        commit('SET_MESSAGE_ERROR',
          i18n.messages[i18n.locale].base.messageError.getEstimate);
        throw new Error(error);
      })
      .finally(() => {
        commit('SET_ESTIMATE_LOADING', false);
      });
  },
  getTypeMeetingAvailable({ commit }) {
    commit('SET_TYPE_MEETING_AVAILABLE_LOADING', true);
    return window.connector
      .getTypeMeetingAvailable()
      .then(async (result) => {
        commit('SET_TYPE_MEETING_AVAILABLE', result);
      })
      .catch((error) => {
        commit('SET_MESSAGE_ERROR',
          i18n.messages[i18n.locale].base.messageError.getTypeMeeting);
        throw new Error(error);
      })
      .finally(() => {
        commit('SET_TYPE_MEETING_AVAILABLE_LOADING', false);
      });
  },
  getOnlineBooking({ commit }) {
    commit('SET_ONLINE_BOOKING_LOADING', true);
    return window.connector
      .getOnlineBooking()
      .then(async (result) => {
        window.location = result.online_booking_url;
      })
      .catch((error) => {
        commit('SET_MESSAGE_ERROR',
          i18n.messages[i18n.locale].base.messageError.onlineBooking);
        throw new Error(error);
      })
      .finally(() => {
        commit('SET_ONLINE_BOOKING_LOADING', false);
      });
  },
  getMeetingsSlots({ commit }, days, duration, date) {
    commit('SET_MEETINGS_SLOT_LOADING', true);
    return window.connector
      .getMeetingsSlots(days, duration, date)
      .then(async ({ meetings }) => {
        if (meetings) {
          commit('SET_MEETING_SLOTS_CONFIG', meetings);
        }
      })
      .catch((error) => {
        commit('SET_MESSAGE_ERROR', i18n.messages[i18n.locale].base.messageError.slotAvailable);
        throw new Error(error);
      })
      .finally(() => {
        commit('SET_MEETINGS_SLOT_LOADING', false);
      });
  },
  postProject({ commit, state }, request) {
    commit('SET_DATA_SAVING', true);
    return window.connector.postProject(JSON.stringify(setParamRequest(request, state)))
      .then((project) => {
        window.connector
          .setProjectToken(project.token);
        window.connector
          .setCrmToken(project.crm_token);
        commit('SET_PROJECT', project);
        commit('SET_DATA_SAVE_ERROR', false);
        commit('SET_ESTIMATE', '');
        commit('SET_TYPE_MEETING_AVAILABLE', '');
      })
      .catch((error) => {
        if (error.error === 'Could not calculate distance.') {
          commit('SET_MESSAGE_ERROR',
            i18n.messages[i18n.locale].base.messageError.calculateDistance);
        } else {
          commit('SET_MESSAGE_ERROR',
            i18n.messages[i18n.locale].base.messageError.saveProject);
        }
        commit('SET_DATA_SAVE_ERROR', true);
        throw new Error(error);
      })
      .finally(() => {
        commit('SET_DATA_SAVING', false);
      });
  },
  postAllowEstimate({ commit, state }, request) {
    commit('SET_DATA_SAVING', true);
    return window.connector.postAllowEstimate(request)
      // eslint-disable-next-line camelcase
      .then(({ allow_estimate }) => {
        if (state.currentStep && state.currentStep.fields) {
          const findAllowEstimate = state.currentStep.fields
            .findIndex((field) => field.id === 'allow_estimate');
          if (findAllowEstimate !== -1 && state.currentStep.fields[findAllowEstimate]) {
            // eslint-disable-next-line camelcase
            state.currentStep.fields[findAllowEstimate].value = allow_estimate;
          } else {
            // eslint-disable-next-line camelcase
            state.currentStep.fields.push({
              id: 'allow_estimate',
              value: allow_estimate,
            });
          }
          // eslint-disable-next-line camelcase
          commit('SET_ALLOW_ESTIMATE', allow_estimate);
        }
        commit('SET_DATA_SAVE_ERROR', false);
      })
      .catch((error) => {
        commit('SET_MESSAGE_ERROR',
          i18n.messages[i18n.locale].base.messageError.saveProject);
        commit('SET_DATA_SAVE_ERROR', true);
        throw new Error(error);
      })
      .finally(() => {
        commit('SET_DATA_SAVING', false);
      });
  },
  updateProject({ commit, state }, request) {
    commit('SET_DATA_SAVING', true);
    return window.connector.updateProject(setParamRequest(request, state))
      .then((project) => {
        commit('SET_PROJECT', project);
        commit('SET_DATA_SAVE_ERROR', false);
        commit('SET_ESTIMATE', '');
        commit('SET_TYPE_MEETING_AVAILABLE', '');
      })
      .catch((error) => {
        if (error.error === 'Could not calculate distance.') {
          commit('SET_MESSAGE_ERROR',
            i18n.messages[i18n.locale].base.messageError.calculateDistance);
        } else {
          commit('SET_MESSAGE_ERROR',
            i18n.messages[i18n.locale].base.messageError.saveProject);
        }
        commit('SET_DATA_SAVE_ERROR', true);
        throw new Error(error);
      })
      .finally(() => {
        commit('SET_DATA_SAVING', false);
      });
  },
  postMeeting({ commit }, request) {
    commit('SET_DATA_SAVING', true);
    return window.connector.postMeeting(JSON.stringify(request))
      .then((meeting) => {
        commit('SET_MEETING', meeting);
      })
      .catch((error) => {
        commit('SET_MESSAGE_ERROR',
          i18n.messages[i18n.locale].base.messageError.postMeeting);
        commit('SET_DATA_SAVE_ERROR', true);
        throw new Error(error);
      })
      .finally(() => {
        commit('SET_DATA_SAVING', false);
      });
  },
  postTypeMeeting({ commit }, typeMeeting) {
    commit('SET_DATA_SAVING', true);
    return window.connector.postTypeMeeting(typeMeeting && typeMeeting.value)
      .then((result) => {
        commit('SET_TYPE_MEETING', typeMeeting);
        commit('SET_MEETING_DURATION', result.meeting_duration);
        commit('SET_DATA_SAVE_ERROR', false);
      })
      .catch((error) => {
        commit('SET_DATA_SAVE_ERROR', true);
        commit('SET_MESSAGE_ERROR', i18n.messages[i18n.locale].base.messageError.updateTypeMeeting);
        throw new Error(error);
      })
      .finally(() => {
        commit('SET_DATA_SAVING', false);
      });
  },
  postCalcMinarmRights({ commit }, request) {
    commit('SET_DATA_SAVING', true);
    return window.connector.postCalcMinarmRights(request)
      .then((result) => {
        commit('SET_MINARM_RIGHTS', {
          verify_plafond_vol: result.verify_plafond_vol,
          verify_plafond_price: result.verify_plafond_price,
        });
        commit('SET_DATA_SAVE_ERROR', false);
      })
      .catch((error) => {
        commit('SET_DATA_SAVE_ERROR', true);
        commit('SET_MESSAGE_ERROR',
          i18n.messages[i18n.locale].base.messageError.minarmRights);
        throw new Error(error);
      })
      .finally(() => {
        commit('SET_DATA_SAVING', false);
      });
  },
};

const getters = {
  projectToken: (state) => state.projectToken,
  crmToken: (state) => state.crmToken,
  estimateLoading: (state) => state.estimateLoading,
  onlineBookingLoading: (state) => state.onlineBookingLoading,
  meetingsSlotsLoading: (state) => state.meetingsSlotsLoading,
  typeMeetingLoading: (state) => state.typeMeetingLoading,
  partnerLoading: (state) => state.partnerLoading,
  isDataSaving: (state) => state.isDataSaving,
  dataSaveError: (state) => state.dataSaveError,
  partnerError: (state) => state.partnerError,
  messageError: (state) => state.messageError,
  scrollFields: (state) => state.scrollFields,
  currentStepNb: (state) => state.currentStepNb,
  steps: (state) => state.steps,
  headerConfig: (state) => state.headerConfig,
  currentStep: (state) => state.currentStep,
  totalSteps: (state) => state.totalSteps,
  partnerStyle: (state) => state.partnerStyle,
  typeMeeting: (state) => state.typeMeeting,
  meetingInfos: (state) => state.meetingInfos,
  meetTypeAvailable: (state) => state.meetTypeAvailable,
  meetingsSlots: (state) => state.meetingsSlots,
  meetingDuration: (state) => state.meetingDuration,
  minarmRights: (state) => state.minarmRights,
  onlineBooking: (state) => state.onlineBooking,
  scrollYAuto: (state) => state.scrollYAuto,
  allowEstimate: (state) => state.allowEstimate,
  estimation: (state) => state.estimation,
  estimationDone: (state) => state.estimationDone,
  fixedHeaderHeight: (state) => state.fixedHeaderHeight,
  configId: (state) => state.configId,
  partnerId: (state) => state.partnerId,
  partnerLeadId: (state) => state.partnerLeadId,
  utmSource: (state) => state.utmSource,
  utmMedium: (state) => state.utmMedium,
  utmCampaign: (state) => state.utmCampaign,
  gclid: (state) => state.gclid,
  msclkid: (state) => state.msclkid,
  customId: (state) => state.customId,
};

const mutations = {
  SET_ACTIVE_STEP(state, { currentStepNb, nextStep }) {
    if (nextStep) {
      const findIndexStep = state.steps.findIndex((step) => step.id === nextStep);
      if (findIndexStep !== 0) {
        currentStepNb = findIndexStep;
      }
    }
    state.messageError = null;
    state.currentStepNb = currentStepNb;
    state.currentStep = state.steps[state.currentStepNb];
    this.dispatch('partnerStore/track');
  },
  SET_ESTIMATE_LOADING(state, value) {
    state.estimateLoading = value;
  },
  SET_TYPE_MEETING_AVAILABLE(state, result) {
    state.meetTypeAvailable = result.meettypeAvailable;
  },
  SET_TYPE_MEETING_AVAILABLE_LOADING(state, value) {
    state.typeMeetingLoading = value;
  },
  SET_PARTNER_ID(state, partnerId) {
    state.partnerId = partnerId;
  },
  SET_DATA_SAVE_ERROR(state, value) {
    state.dataSaveError = value;
  },
  SET_MESSAGE_ERROR(state, message) {
    state.messageError = message;
  },
  SET_PARTNER_ERROR(state, value) {
    state.partnerError = value;
  },
  SET_SCROLL_FIELDS(state, value) {
    state.scrollFields = value;
  },
  SET_CONFIG_ID(state, configId) {
    state.configId = configId;
  },
  SET_ALLOW_ESTIMATE(state, allowEstimate) {
    state.allowEstimate = allowEstimate ? 'allow' : 'disallow';
  },
  SET_TYPE_MEETING(state, typeMeeting) {
    state.typeMeeting = typeMeeting;
  },
  SET_MEETING_DURATION(state, duration) {
    state.meetingDuration = duration;
  },
  SET_MINARM_RIGHTS(state, minarmRights) {
    state.minarmRights = minarmRights;
  },
  SET_PARTNER_LEAD_ID(state, id) {
    if (id) {
      state.partnerLeadId = id;
    }
  },
  SET_UTM_SOURCE(state, utm) {
    state.utmSource = utm;
  },
  SET_UTM_MEDIUM(state, utm) {
    state.utmMedium = utm;
  },
  SET_UTM_CAMPAIGN(state, utm) {
    state.utmCampaign = utm;
  },
  SET_GCLID(state, gclid) {
    state.gclid = gclid;
  },
  SET_MSCLKID(state, msclkid) {
    state.msclkid = msclkid;
  },
  SET_INITIAL_DATA(state, initialData) {
    state.initialData = JSON.parse(initialData);
  },
  SET_PARTNER_CONFIG(state, result) {
    this.commit('analyticsStore/SET_GA_DEACTIVATE',
      !!result.config.ga_deactivate || this.getters['analyticsStore/gaDeactivate']);
    this.dispatch('analyticsStore/iniGtag');
    if (!result.config.disable_error_reporting) {
      Bugsnag(process.env);
    }
    state.partnerStyle = result.style;
    state.steps = [...result.config.steps];
    if (state.initialData) {
      let errorField = false;
      for (const [index, step] of Object.entries(state.steps)) {
        for (const [i, field] of Object.entries(step.fields)) {
          for (const data of Object.entries(state.initialData)) {
            if (field.id === data[0]) {
              // eslint-disable-next-line prefer-destructuring
              field.value = data[1];
              // SAVE INITIAL VALUE
              if (data[1] !== undefined) {
                // eslint-disable-next-line prefer-destructuring
                field.initialValue = data[1];
              }
            }
          }
          if (!errorField && step.force_next_step) {
            errorField = !!fieldUtils.isErrorField(field);
            // eslint-disable-next-line radix
            state.currentStepNb = Number.parseInt(index);
            if (!errorField) {
              field.visibility = true;
              // eslint-disable-next-line radix
              if (Number.parseInt(i) + 1 === step.fields.length) {
                this.dispatch('partnerStore/track');
              }
              state.currentStepNb += 1;
            }
          }
        }
      }
      if (state.steps && state.steps[0] && state.steps[0].action === 'check_allow_estimate'
        && state.steps[0].fields && state.currentStepNb > 0) {
        this.dispatch('partnerStore/postAllowEstimate', fieldUtils.cleanData([state.steps[0]]));
        state.currentStepNb -= 1;
      }
    }
    [
      { id: 'scrollYAuto', name: 'scroll_y_auto' },
      { id: 'fixedHeaderHeight', name: 'fixed_header_height' },
      { id: 'onlineBooking', name: 'online_booking' }].forEach((setting) => {
      state[setting.id] = result.config[setting.name];
    });
    state.totalSteps = state.steps.length;
    state.currentStep = state.steps[state.currentStepNb];
    state.currentStep.readyForAutoScroll = true;
    if (result.config && result.config.header) {
      state.headerConfig = result.config.header;
    }
    this.dispatch('partnerStore/track');
  },
  SET_ESTIMATE(state, result) {
    if (result.estimation && result.estimation.verylow_estimate) {
      delete result.estimation.verylow_estimate;
    }
    state.estimationDone = 1;
    state.estimation = {
      price: result.estimation,
      volume: result.volume,
      distance: result.distance,
    };
  },
  SET_MEETING(state, result) {
    state.meetingInfos = result;
  },
  SET_MEETING_SLOTS_CONFIG(state, result) {
    state.meetingsSlots = result;
  },
  SET_PROJECT(state, result) {
    state.projectToken = result.token;
    state.crmToken = result.crm_token;
    if (!state.onlineBooking
      && state.meetTypeAvailable && state.meetTypeAvailable.length) {
      removeItem(state.meetTypeAvailable, 'value', 'ONLINE');
    }
  },
  SET_PARTNER_LOADING(state, value) {
    state.partnerLoading = value;
  },
  SET_ONLINE_BOOKING_LOADING(state, value) {
    state.onlineBookingLoading = value;
  },
  SET_MEETINGS_SLOT_LOADING(state, value) {
    state.meetingsSlotsLoading = value;
  },
  SET_DATA_SAVING(state, value) {
    if (value) {
      state.messageError = null;
    }
    state.isDataSaving = value;
  },
  SET_CUSTOM_ID(state, value) {
    state.customId = value;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
