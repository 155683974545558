import inventory from '@nextories/vue-core-components/src/i18n/langs/fr/inventory';

export default {
  ...inventory,
  genericRooms: [
    {
      id: 'bedroom',
      name: 'Chambre',
      piece_type: 'bedroom',
      value: 'bedroom',
      meubles: [],
    },
    {
      id: 'bathroom',
      name: 'Salle de bain',
      piece_type: 'bathroom',
      value: 'bathroom',
      meubles: [],
    }, {
      id: 'kitchen',
      name: 'Cuisine',
      value: 'kitchen',
      piece_type: 'kitchen',
      meubles: [],
    },
    {
      id: 'living_room',
      name: 'Salon',
      value: 'living_room',
      piece_type: 'living_room',
      meubles: [],
    },
    {
      id: 'garage_cellar',
      name: 'Garage / Cave',
      value: 'garage_cellar',
      piece_type: 'garage_cellar',
      meubles: [],
    },
  ],
  furniture: 'Meubles {furnitureNb}',
  volume: 'Volume {volume}',
};
