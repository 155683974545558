<template>
  <div :class="$setClasses">
    <div :class="$vClass('wrapper')">
      <div v-if="beforeContent"
           v-html="beforeContent"
           :class="$vClass('before-content')" />
      <p :class="$vClass('price')">{{ price
        ? $t('estimate.simulator.price', {
          min: $filters.format.convertToInteger(price * minRatio, '€'),
          max: $filters.format.convertToInteger(price * maxRatio, '€')})
        : $filters.format.convertToInteger(price, '€')
        }}</p>
      <div v-if="afterContent"
           v-html="afterContent"
           :class="$vClass('after-content')" />
      <nxt-btn v-if="buttonNext"
               :class="['nxt-button nxt-button__primary', $vClass('button')]"
               :title="buttonNext.text || currentStep && currentStep.text_button_next
                || $t('base.next')"
               data-cy="next-button"
               type="button"
               form="form"
               @click="$helpers['event-bus'].$emit('validateForm',
                {action: buttonNext.action})"></nxt-btn>
    </div>
  </div>
</template>

<script>
/* eslint-disable camelcase */
import { mapGetters } from 'vuex';
import { fourchette_basse, fourchette_groupage, fourchette_haute } from '@nextories/python-estimation';

export default {
  nameClassStyle: 'estimation-simulator',
  name: 'EstimationSimulator',
  props: {
    afterContent: {
      type: String,
    },
    beforeContent: {
      type: String,
    },
    refModels: {
      type: Array,
    },
    forceSurfaceToVolume: {
      type: Boolean,
    },
    buttonNext: {
      type: [Object, Boolean],
    },
  },
  data() {
    return {
      volume: null,
      distance: 0,
      surface: 0,
      price: 0,
      isFlexible: false,
      formule: 'FORM_ECO',
      date: 1,
      minRatio: 0.95,
      maxRatio: 1.15,
      surfaceToVolumeConverter: 0.5,
      defaultModels: [
        {
          id: 'surfaceToVolumeConverter',
          model: 'surfaceToVolumeConverter',
        },
        {
          id: 'date_from',
          model: 'date',
        },
        {
          id: 'surface',
          model: 'surface',
        },
        {
          id: 'volume',
          model: 'volume',
        },
        {
          id: 'distance',
          model: 'distance',
        },
        {
          id: 'formule',
          model: 'formule',
        },
        {
          id: 'date_flexible',
          model: 'isFlexible',
        },
      ],
    };
  },
  computed: {
    ...mapGetters({
      steps: 'partnerStore/steps',
      currentStep: 'partnerStore/currentStep',
    }),
    // Synchronize rer models in config this field with default rer models
    syncRefModels() {
      const refModels = {};
      if (this.defaultModels) {
        if (this.refModels && this.refModels.length) {
          for (const field of this.defaultModels) {
            const findRefField = this.refModels.find(({ model }) => model === field.model);
            if (findRefField) {
              const model = findRefField.id || field.id;
              refModels[model] = Object.assign(field, findRefField);
            } else {
              refModels[field.id] = field;
            }
          }
          return refModels;
        }
        return this.$helpers.format.convertArrayToObject(this.defaultModels);
      }
      return refModels;
    },
  },
  created() {
    this.setValue();
    this.calcEstimation();
  },
  methods: {
    getModel(key) {
      return this.syncRefModels[key] && this.syncRefModels[key].model;
    },
    // Synchronize values with model
    // check rer models and refValueFieldId
    setValue() {
      const fields = {};
      const refValueFields = [];
      let volumeFromFields = null;
      for (const step of this.steps) {
        for (let i = 0; i < step.fields.length; i += 1) {
          const field = step.fields[i];
          const model = this.getModel(field.id);
          if (model && this.$helpers.fieldUtils
            .checkRefField(field.value, this.syncRefModels[field.id], true)) {
            // Not set model if field has refValueFieldId or value has refValueFieldId
            if (field.refValueFieldId || (field.value && field.value.refValueFieldId)) {
              refValueFields.push(field);
            } else {
              this[model] = this.$helpers.format.convertToNumber(field.value);
              if (model === 'volume') {
                volumeFromFields = this.volume;
              }
            }
          }
          fields[field.id] = field.value;
        }
      }

      // Set model with refValueFieldId
      for (const field of refValueFields) {
        const refId = field.refValueFieldId || (field.value && field.value.refValueFieldId);
        const model = this.getModel(field.id);
        this[model] = this.$helpers.format.convertToNumber(fields[refId]);
        if (model === 'volume') {
          volumeFromFields = this.volume;
        }
      }

      // Set volume with surface to volume converter
      if (this.forceSurfaceToVolume || volumeFromFields == null) {
        this.volume = this.surface > 0 ? this.surface * this.surfaceToVolumeConverter : 0;
      }
    },
    // Check date is low season
    isHighSeason(date) {
      if (typeof date === 'number') return date === 1;
      const dateTime = new Date(date);
      const day = dateTime.getDate();
      const month = dateTime.getMonth() + 1;
      if (month >= 6 && month <= 9) {
        if (month === 9) {
          return day <= 10;
        }
        return true;
      }
      if (month === 1) {
        return day < 10;
      }
      if (month === 12) {
        return day >= 15;
      }
      return false;
    },
    calcFormulePrice() {
      if (this.formule === 'FORM_STANDARD') {
        return 100 + (this.volume - 10) * 3;
      }
      if (this.formule === 'FORM_LUXE') {
        return 250 + (this.volume - 10) * 8;
      }
      return 0;
    },
    // Get the corresponding estimate (fourchette_basse, fourchette_groupage, fourchette_haute),
    // generated in JSON by Python
    getEstimateRange({
      volume, distance, isFlexible, date,
    }) {
      const isHighSeason = this.isHighSeason(date);
      isFlexible = distance > 180 ? !!isFlexible : false;
      if (volume <= 15 && isFlexible) {
        return {
          calcFormule: 'calcFormuleGroupage',
          estimateRange: fourchette_groupage,
          applyCoeff: !isHighSeason,
        };
      }
      if (isHighSeason) {
        return {
          calcFormule: 'calcFormuleOther',
          applyCoeff: isFlexible,
          estimateRange: fourchette_haute,
        };
      }
      return {
        calcFormule: 'calcFormuleOther',
        applyCoeff: isFlexible,
        estimateRange: fourchette_basse,
      };
    },
    calcFormuleGroupage({
      distance, intercept,
      volume, price_min,
    }, coeff) {
      return Math.max(price_min, (1 + this.distance / distance)
        * (intercept - volume * this.volume) * this.volume) * coeff;
    },
    calcFormuleOther({ distance, intercept, volume }, coeff) {
      return (intercept + distance * this.distance + volume * this.volume) * coeff;
    },
    checkDistanceOrVolume([min, max], model) {
      return (min === undefined || min <= model) && (max === undefined || max >= model);
    },
    // Calc estimation
    calcEstimation() {
      // Init price if not volume
      if (!this.volume) {
        this.price = 0;
        return;
      }
      // To get the list of coefficients
      const { estimateRange, calcFormule, applyCoeff } = this.getEstimateRange(this);
      // Calculate the price in relation to the season coefficients
      if (estimateRange) {
        for (const { distance, volume, coeffs } of estimateRange) {
          // If the volume and distance are within the data intervals
          if (this.checkDistanceOrVolume(distance, this.distance)
            && this.checkDistanceOrVolume(volume, this.volume)) {
            // Set the price
            this.price = this[calcFormule](coeffs, applyCoeff ? coeffs.variable_coeff : 1)
              + this.calcFormulePrice();
            break;
          }
        }
      }
    },
  },
  watch: {
    steps: {
      deep: true,
      handler() {
        this.setValue();
        this.calcEstimation();
      },
    },
  },
};
</script>

<style lang="scss" scoped>
.#{$prefixName}estimation-simulator {

  &__wrapper {
    margin: var(--nxt-space-small) 0 var(--nxt-space-large);
    padding: var(--nxt-space-medium) var(--nxt-space-large);
    border-radius: var(--nxt-border-radius-base);
    text-align: center;
    font-weight: var(--nxt-font-weight-bold);
    background-color: var(--nxt-color-secondary);
    color: var(--nxt-color-white);
  }

  &__price {
    font-size: var(--nxt-font-size-xlarge);
    color: var(--nxt-color-primary)
  }

  &__after-content {
    font-size: var(--nxt-font-size-medium);
  }

  &__before-content {
    font-size: var(--nxt-font-size-xlarge);
  }

  &__button {
    color: var(--nxt-color-white);
    display: initial;
    margin-top: var(--nxt-space-medium);

    &:hover {
      text-shadow: var(--nxt-text-shadow-secondary);
    }
  }
}
</style>
