<script>
import Layout from '@nextories/vue-core-components/src/components/templates/Layout.vue';

export default {
  extends: Layout,
  name: 'Layout',
};
</script>

<style lang="scss" scoped>
.el-aside {
  overflow: hidden;
  display: none;
}

.el-main {
  overflow: hidden;
  padding: inherit;
  display: flex;
  flex-direction: column;
}

.#{$prefixName}layout {
  height: 100%;

  &__main {
    overflow-y: scroll;
    overflow-x: hidden;
    scroll-behavior: smooth;

    &__header {
      padding: 0;

      &--desktop {
        display: none;
      }
    }
  }
}

.#{$prefixName}no-scroll {
  overflow: hidden;
}
</style>
