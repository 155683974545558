import rooms from '@nextories/vue-core-components/src/i18n/langs/fr/rooms';
import input from '@nextories/vue-core-components/src/i18n/langs/fr/input';
import base from './base';
import estimate from './estimate';
import inventory from './inventory';
import meeting from './meeting';
import minarmRights from './minarmRights';

const lang = {
  rooms,
  base,
  input,
  estimate,
  inventory,
  meeting,
  minarmRights,
};

export default lang;
