import {
  configure, extend, localize, ValidationObserver, ValidationProvider,
} from 'vee-validate';
import fr from 'vee-validate/dist/locale/fr.json';
import * as rules from 'vee-validate/dist/rules';
import i18n from '../../i18n';

export default function setup(Vue) {
  const config = {
    classes: {
      valid: 'is-valid',
      invalid: 'is-invalid',
    },
  };
  configure(config);
  Object.keys(rules)
    .forEach((rule) => {
      extend(rule, rules[rule]);
    });
  extend('address', (value) => {
    if (value.city && value.city !== '') {
      return !Vue.prototype.$helpers.gmaps.hasErrorAddressPostal(value.postal, value.country)
        || i18n.messages[i18n.locale].base.validation.address.regex;
    }
    return i18n.messages[i18n.locale].base.validation.address.regex;
  });
  extend('address-postal', (value) => value.match(/^(?:[0-8]\d|9[0-8])\d{3}$/) || '');
  extend('date', (value) => {
    const today = new Date();
    today.setHours(0, 0, 0, 0);
    return Date.parse(today) <= Date.parse(value)
      || i18n.messages.fr.base.validation.date.regex;
  });
  extend('exclude_values', {
    validate(value, args) {
      return !Vue.prototype.$helpers.format.checkExcludeValues(args && args.excludeValues, value)
        ? i18n.messages[i18n.locale].base.validation.excludeValues : true;
    },
    params: ['excludeValues'],
  });
  extend('address-city', (value) => value !== '' || '');
  localize('fr', fr);
  Vue.component('ValidationObserver', ValidationObserver);
  Vue.component('ValidationProvider', ValidationProvider);
}
