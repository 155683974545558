<template>
  <div :class="$setClasses">
    <nxt-text-content v-if="title" :class="[$vClass('title'),
                    indexStep > 0 && '--margin-top']"
                      tag="h2"
    >{{ title }}
    </nxt-text-content>
    <template v-for="(field, index) in fields">
      <transition :key="'field' + index" :name="$vPrefix('fade')" mode="out-in">
        <input-container v-if="showField(index)"
                         :id="`${field.name || field.id}-field`"
                         ref="input-container"
                         :class="[$vClass('content'),
                            field.class ? field.class : '',
                            field.type === 'script-content' ? '--display-none' : '']"
                         :currentStep="step"
                         :field="field"
                         :indexField="index"
                         :scrollField="index === indexNextField"
                         @nextFields="nextFields"/>
        <div v-else-if="field.show_text" :class="$vClass('content')">
          <field :label="field.display_name"
                 class="--direction-row">
            {{ field.value }}
          </field>
        </div>
      </transition>
    </template>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import InputContainer from '@/organisms/InputContainer.vue';
import Field from '@/molecules/Field.vue';

export default {
  name: 'input-fields',
  nameClassStyle: 'input-fields',
  components: {
    InputContainer,
    Field,
  },
  props: {
    step: Object,
    title: String,
    indexStep: Number,
    fields: Array,
  },
  data() {
    return {
      autoFocusInput: true,
      indexNextField: 0,
      scrollNextField: false,
    };
  },
  computed: {
    ...mapGetters({
      allSteps: 'partnerStore/steps',
    }),
  },
  created() {
    if (this.step && this.step.transition) {
      this.nextFields();
      this.focusInput(0);
    }
  },
  methods: {
    focusInput(indexInput) {
      if (indexInput === 0 || !this.autoFocusInput || !this.step.transition) {
        return;
      }
      setTimeout(() => {
        const inputContainer = this.$refs['input-container'];
        if (inputContainer && inputContainer.length) {
          indexInput = indexInput >= 0 ? indexInput : inputContainer.length - 1;
          if (inputContainer[indexInput] && inputContainer[indexInput].$refs) {
            const elInput = inputContainer[indexInput].$refs['el-input'];
            if (elInput && elInput.$refs && (elInput.$refs['input-text'] || elInput.$refs.autocomplete)) {
              const typeInput = elInput.$refs['input-text']
                || elInput.$refs.autocomplete;
              typeInput.focus();
            }
          }
        }
      }, 100);
    },
    // The function displays the following fields when the previous field is valid
    nextFields(scrollField, indexNextField) {
      // Test if there is a scroll to the next field
      if (scrollField) {
        // Save index next field for scroll
        this.indexNextField = indexNextField;
        this.scrollNextField = scrollField;
      }
      let error = false;
      // For display auto first input
      let firstField = true;
      if (this.step && this.step.fields) {
        for (const [index, field] of this.step.fields.entries()) {
          // Test if the field is valid
          error = this.$helpers.fieldUtils.isErrorField(field);
          if (firstField && field.type && !field.hide) {
            this.$set(field, 'visibility', true);
            // Set first field to false for not set auto visibility
            firstField = false;
          }
          if (error) {
            return;
          }
          // Set visibility for next fields
          if (this.step.fields[index + 1] && !this.step.fields[index + 1].visibility) {
            this.$set(this.step.fields[index + 1], 'visibility', true);
          }
          // Set autoFocusInput for next fields
          this.autoFocusInput = index + 1 < this.step.fields.length;
        }
        this.focusInput();
      }
    },
    // Test if all the conditions are valid to display the field
    showField(index) {
      const field = this.step.fields[index];
      let refFieldCondition;
      // Check field has ref fields
      if (field.ref_fields && field.ref_fields.length) {
        // Find its parent in all steps and if it is valid
        refFieldCondition = this.$helpers.fieldUtils
          .isValidRefFields(field.ref_fields, this.allSteps);
      }
      // Display the field, if the type exists and the attributes 'hide', 'show_text' do not exist
      if (field.type && !field.show_text
        && !field.hide) {
        // If the field is a ref field test condition for display
        if (refFieldCondition !== undefined) {
          // Save initial required setting
          if (!field.defaultRequired) {
            this.$set(field, 'defaultRequired', field.required);
          }
          const newVisibility = (!this.step.transition || field.visibility) && refFieldCondition;
          this.$set(field, 'required', field.defaultRequired && refFieldCondition);
          this.$set(field, 'visibility', newVisibility);
          // Reset field value
          if (!field.visibility && field.value !== undefined && field.value !== null) {
            this.$set(field, 'value', field.initialValue || '');
            this.$set(field, 'displayFieldError', false);
          }
          // Auto focus input
          this.focusInput();
          return newVisibility;
        }
        // Auto focus input
        this.focusInput();
        // If there is no transition between the fields, the field is displayed,
        // otherwise it is in relation to the visibility attribute
        return this.step.transition ? field.visibility : true;
      }
      return false;
    },
  },
};
</script>

<style lang="scss" scoped>
.#{$prefixName}input-fields {
  .--display-none {
    display: none;
  }

  &__title {
    margin-bottom: var(--nxt-space-large);
    font-weight: var(--nxt-font-weight-bold);
    font-size: var(--nxt-font-size-xlarge);

    &.--margin-top {
      margin-top: var(--nxt-space-xxlarge);
    }
  }

  &__content:not(:first-child) {
    padding-top: var(--nxt-space-default);
  }
}
</style>
