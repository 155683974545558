<template>
  <div :id="`nxt-app${customId ? ` ${customId}` : ''}`"
       :class="[$setClasses, 'custom', currentStep ? `--${currentStep.id}` : '']">
    <template v-if="!partnerLoading && !partnerError">
      <div v-if="headerConfig && headerConfig.content"
           v-html="headerConfig.content"
           :class="$vPrefix('header')"
      ></div>
      <transition :name="$vPrefix('fade')" mode="out-in">
        <multi-step-form :class="$vClass('content')"/>
      </transition>
    </template>
    <div v-else-if="partnerLoading" :class="$vClass('loading')">
      <nxt-loader :text="$t('base.loading')" sizeLoader="--size-small"/>
    </div>
    <div v-else :class="$vClass('error')">
      <nxt-text-content :class="$vClass('error__title')" tag="h1">
        {{ $t('base.messageError.form') }}
      </nxt-text-content>
      <nxt-text-content :class="$vClass('error__subtitle')">
        {{ $t('base.contactAdmin') }}
      </nxt-text-content>
      <nxt-link :class="[$vClass('error__link'), 'nxt-button nxt-button__primary']"
                :to="$t('base.phone.link')" isExternal
                target="_blank">
        {{ $t('base.phone.text') }}
      </nxt-link>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import NxtApp from '@nextories/vue-core-components/src/components/templates/App.vue';
import MultiStepForm from '@/templates/MultiStepForm.vue';
// eslint-disable-next-line import/no-extraneous-dependencies

export default {
  extends: NxtApp,
  nameClassStyle: 'app',
  components: {
    MultiStepForm,
  },
  created() {
    if (this.configId) {
      this.getPartner({
        partnerId: this.partnerId,
        configId: this.configId,
      });
    }
  },
  computed: {
    ...mapGetters({
      configId: 'partnerStore/configId',
      partnerId: 'partnerStore/partnerId',
      customId: 'partnerStore/customId',
      partnerStyle: 'partnerStore/partnerStyle',
      partnerLoading: 'partnerStore/partnerLoading',
      currentStepNb: 'partnerStore/currentStepNb',
      partnerError: 'partnerStore/partnerError',
      headerConfig: 'partnerStore/headerConfig',
      currentStep: 'partnerStore/currentStep',
    }),
  },
  methods: {
    ...mapActions({
      getPartner: 'partnerStore/getPartner',
    }),
  },
  watch: {
    partnerStyle(customStyle) {
      const partnerCustom = customStyle;
      const addStyleTag = document.createElement('style');
      addStyleTag.appendChild(document.createTextNode(partnerCustom));
      document.head.appendChild(addStyleTag);
    },
    currentStepNb() {
      this.$helpers.gmaps.removePacContainers();
    },
  },
};
</script>

<style lang="scss" scoped>
.#{$prefixName}app {
  background-color: var(--nxt-color-white);
  font-size: var(--nxt-font-size-default);
  color: var(--nxt-color-text-primary);
  padding: 0;
  margin: 0 auto;
  width: 100%;
  height: 100%;
  min-height: 90vh;
  max-width: 1280px;
  overflow-x: hidden;

  &__error {
    padding: var(--nxt-space-medium);
    background-color: var(--nxt-color-secondary);
    text-align: center;
    color: var(--nxt-color-white);

    &__title {
      color: var(--nxt-color-primary);
      font-size: var(--nxt-font-size-large);
    }

    &__subtitle {
      color: var(--nxt-color-white);
      font-size: var(--nxt-font-size-default);
    }

    &__link {
      margin: auto;
      height: auto;
      width: 150px;
    }
  }

  :deep(.el-main) {
    scrollbar-width: none;
    -ms-overflow-style: -ms-autohiding-scrollbar;
  }

  ::-webkit-scrollbar {
    width: 0;
    display: none;
    @include breakpoint(tablet) {
      display: unset;
      height: 5px;
    }
  }

  /* Track */
  ::-webkit-scrollbar-track {
    background: var(--nxt-color-light-grey-blue);
    border-radius: var(--nxt-border-radius-base);
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: var(--nxt-color-secondary);
    border-radius: var(--nxt-border-radius-base);
  }

  /* Handlesource bin/activate && python manage.py runserver localhost:9000 on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: var(--nxt-color-secondary);
  }

  :deep(*),
  :deep(svg:not(:root).svg-inline--fa),
  :deep(svg:not(:host).svg-inline--fa) {
    box-sizing: border-box;
    user-select: none;
  }

  :deep(input),
  :deep(textarea){
    user-select: text;
  }

  h2 {
    margin: 0;
    font-weight: var(--nxt-font-weight-bold);
    font-size: var(--nxt-font-size-medium);
  }

  :deep(ul) {
    padding: 0;
  }

  :deep(li) {
    list-style: none;
  }

  &__content {
    height: auto;
  }

  &__loading {
    height: 100%;
    width: 100%;
  }

  :deep(.#{$prefixName}input-select) {
    width: 100%;

    .el-select__tags-text {
      color: var(--nxt-color-grey-blue);
    }

    .el-tag__close.el-icon-close {
      color: var(--nxt-color-white);
      background-color: var(--nxt-color-primary);
      font-weight: var(--nxt-font-weight-bold);
    }
  }

  :deep(.#{$prefixName}inventory) {
    .#{$prefixName}--hidden {
      visibility: hidden;
    }

    &__top {
      height: 75px;
      @include breakpoint(mobileWithHeight) {
        height: 90px;
      }
    }

    &__label {
      .#{$prefixName}input-label {
        margin-top: 5px;
        margin-bottom: 15px;
      }
    }

    &__header {
      display: flex;
      grid-template-columns: auto auto;
      place-items: baseline;

      &__add-room {
        display: inline-block;
      }

      &__room-item {
        padding: 5px 10px;
        margin: 0 7px;
        background-color: var(--nxt-color-light-grey-blue);
        border-radius: var(--nxt-border-radius-base);

        &--active {
          background-color: var(--nxt-color-secondary);
          color: var(--nxt-color-white);
          border-radius: var(--nxt-border-radius-base);
        }

        &--edit {
          background: white;
          padding: 0;
        }
      }

      &__icon {
        font-size: var(--nxt-font-size-large);
      }
    }

    &__room-selector {
      margin: 10px 0;
      display: grid;
      grid-template-columns: 1fr auto auto;
      align-items: center;

      &__burger_menu {
        margin: 0 .8em;
        cursor: pointer;
      }
    }

    &__room-delete.#{$prefixName}dialog {
      .#{$prefixName}dialog__content__header {
        position: inherit !important;
      }
    }

    .#{$prefixName}inline-selector {
      &__list {
        overflow-x: hidden;

        &__item {
          margin-bottom: 0;
        }
      }
    }

    @include breakpoint(mobileWithHeight) {
      &__room-delete .#{$prefixName}dialog__content {
        height: auto;
      }
    }

    .#{$prefixName}furniture-selector {
      overflow: hidden;
      @include breakpoint(mobileWithHeight) {
        border: 1px solid var(--nxt-color-text-secondary);
        border-radius: var(--nxt-border-radius-base);
        height: fit-content;
        &__search-bar {
          border-top: none;
          grid-template-columns: minmax(100px, 500px) auto;
          margin: 0;
          border-radius: var(--nxt-border-radius-base) var(--nxt-border-radius-base) 0 0;
          height: 60px;
        }
      }

      &-body {
        ::-webkit-scrollbar {
          @include breakpoint(mobileWithHeight) {
            display: block !important;
            height: 5px;
            background: var(--nxt-color-secondary);
            border-radius: var(--nxt-border-radius-base);
          }
        }

        display: block;

        &__header {
          overflow: hidden;
          height: 60px;
          margin: 0;
          width: auto;
          @include breakpoint(desktop) {
            padding: 10px;
          }
          @include breakpoint(mobileWithHeight) {
            height: 60px;
          }

          .#{$prefixName}selector-arrow__right {
            padding-right: 0;
          }
        }

        &__furniture-list {
          scrollbar-width: none;
          -ms-overflow-style: -ms-autohiding-scrollbar;
          @include breakpoint(mobileWithHeight) {
            padding-bottom: 0;
            height: calc(95vh - 385px);
            scrollbar-color: var(--nxt-color-secondary) var(--nxt-color-light-grey-blue);
            scrollbar-width: thin;
          }
          @include breakpoint(desktop) {
            grid-template-columns: repeat(auto-fill, 170px);
          }
          @include breakpoint(desktopWithHeight) {
            height: calc(80vh - 385px);
          }

          &::-webkit-scrollbar:vertical {
            width: 5px;
          }

          &__item {
            &.#{$prefixName}furniture-card {
              @include breakpoint(mobileWithHeight) {
                max-width: 160px;
              }
            }
          }
        }
      }
    }
  }

  :deep(.#{$prefixName}furniture-modal__footer__text),
  :deep(.#{$prefixName}inventory__footer__text) {
    display: grid;
    grid-template-columns: auto auto;
    margin: 0;
    background-color: var(--nxt-color-light-grey-blue);
    padding: 0.7rem 0;
    font-size: var(--nxt-font-size-small);
    border-radius: var(--nxt-border-radius-base);

    p {
      justify-self: center;
      margin: 0;
    }

    @include breakpoint(mobileWithHeight) {
      font-size: var(--nxt-font-size-large);
      border-radius: 0;
      border-bottom: {
        right-radius: var(--nxt-border-radius-base);
        left-radius: var(--nxt-border-radius-base);
      }
      height: 45px;
    }
  }

  :deep(.#{$prefixName}field__label) {
    font-size: var(--nxt-font-size-small);

    @include breakpoint(mobileWithHeight) {
      font-size: var(--nxt-font-size-medium);
    }
  }

  .#{$prefixName}fade {
    &-enter-active, &-leave-active {
      transition: opacity 200ms;
    }

    &-enter, &-leave-to {
      opacity: 0;
    }
  }

  :deep(.#{$prefixName}dialog) {
    z-index: 9999;
    height: 100%;
  }
}
</style>
