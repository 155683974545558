import Connector from '@nextories/vue-core-components/src/utils/api';

export default class Api extends Connector {
  setPartner(id) {
    this.partner = id;
  }

  setProjectToken(token) {
    this.projectToken = token;
  }

  setCrmToken(token) {
    this.crmToken = token;
  }

  /**
   * Retrieve the project of the current user from the backend
   * @returns {Promise<Object>} resolve with the project for the current user
   * or reject with the backend error message
   */
  getPartner(partnerId, configId) {
    let query = `config_id=${configId}`;
    if (partnerId) {
      this.setPartner(partnerId);
      query = `partner=${partnerId}&config_id=${configId}`;
    }
    // eslint-disable-next-line no-underscore-dangle
    return super._getData(`booking/config-partner?${query}`);
  }

  getMeetingsSlots({
    days,
    duration,
    date,
  }) {
    // eslint-disable-next-line no-underscore-dangle
    return super._getData(`/booking/meeting/${this.crmToken || this.projectToken}?days=${days}&duration=${duration}&date=${date}`);
  }

  getOnlineBooking() {
    // eslint-disable-next-line no-underscore-dangle
    return super._getData(`/booking/online/${this.crmToken || this.projectToken}`);
  }

  getTypeMeetingAvailable() {
    // eslint-disable-next-line no-underscore-dangle
    return super._getData(`/booking/mettype-available/${this.projectToken}`);
  }

  postAllowEstimate(request) {
    // eslint-disable-next-line no-underscore-dangle
    return super._postData('/booking/check-allow-estimate/', request);
  }

  getEstimation(request) {
    // eslint-disable-next-line no-underscore-dangle
    return super._postData('/static/estimation/', request);
  }

  getEstimate() {
    // eslint-disable-next-line no-underscore-dangle
    return super._getData(`/booking/estimation/${this.projectToken}`);
  }

  postProject(request) {
    // eslint-disable-next-line no-underscore-dangle
    return super._postData('/booking/api-partner/', request);
  }

  postTypeMeeting(typeMeeting) {
    // eslint-disable-next-line no-underscore-dangle
    return super._getData(`/booking/meeting-type/${this.projectToken}?type_meeting=${typeMeeting}`);
  }

  postMeeting(request) {
    // eslint-disable-next-line no-underscore-dangle
    return super._postData(`/booking/meeting/${this.crmToken || this.projectToken}`, request);
  }

  postCalcMinarmRights(request) {
    request.partner = this.partner;
    // eslint-disable-next-line no-underscore-dangle
    return super._postData('/booking/calcul-plafond-minarm/', JSON.stringify(request));
  }

  updateProject(request) {
    request.token = this.projectToken;
    request.crm_token = this.crmToken;
    // eslint-disable-next-line no-underscore-dangle
    return super._postData('/booking/api-partner/', JSON.stringify(request));
  }
}

window.connector = new Api();
