export default {
  price: 'Entre {low} € et {high} €',
  loading: 'Nous calculons votre estimation...',
  message: 'Calcul de votre estimation pour un volume de {volume} à déménager sur une distance de {distance} km.',
  formula: {
    low_estimate: {
      id: 'low_estimate',
      initInfo: true,
      description: [
        'Protection du mobilier et literie',
        'Transport et remise en place de vos biens',
        'Fourniture de cartons',
        'Démontage et remontage du mobilier',
      ],
    },
    average_estimate: {
      id: 'average_estimate',
      initInfo: true,
      description: [
        'FORMULE BASIQUE',
        'Emballage et déballage de vos biens fragiles',
        'Mise en penderie de vos habits sur cintres',
      ],
    },
    high_estimate: {
      id: 'high_estimate',
      initInfo: true,
      description: [
        'FORMULE STANDARD',
        'Emballage de vos biens non fragiles',
      ],
    },
  },
  simulator: {
    afterContent: 'Cette estimation est une simulation du coût de votre déménagement.',
    beforeContent: '<p class="u-margin-top-0">Simulation du coût de votre déménagement</p>',
    price: '{min} - {max}',
  },
};
