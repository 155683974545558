import Vue from 'vue';
import nxtVue from '@nextories/vue-core-components';
import axios from '@nextories/vue-core-components/src/utils/modules/axios';
import '@nextories/vue-core-components/src/assets/scss/external/element-variables.scss';
import './utils/api';
import './assets/scss/app.scss';
import helpers from './utils/helpers/index';
import modules from './utils/modules/index';
import App from './App.vue';
import store from './store/index';
import i18n from './i18n';

Vue.use(nxtVue);
Vue.prototype.$API = axios();
Object.assign(Vue.prototype.$helpers, helpers);
modules.elementUI.setup(Vue);
modules.veeValidate(Vue);
Vue.config.productionTip = false;

new Vue({
  data() {
    return {
      prefixCSS: process.env.nxt.prefixCSS ? `${process.env.nxt.prefixCSS}-` : '',
    };
  },
  beforeMount() {
    const el = this.$el;
    const partnerStore = 'partnerStore/';
    const analyticsStore = 'analyticsStore/';
    if (window.nxtConfigLocal
      || this.$helpers.setParametersStore.setValueStore(el, 'config-id', 'config_id', `${partnerStore}SET_CONFIG_ID`)) {
      this.$helpers.setParametersStore.setValueStore(el, 'partner-id', 'partner', `${partnerStore}SET_PARTNER_ID`);
      for (const item of [{ value: 'utm_source', dataIsPriority: true },
        { value: 'utm_medium', dataIsPriority: true }, { value: 'utm_campaign', dataIsPriority: true },
        'gclid', 'msclkid', { value: 'partner_lead_id', notCookie: true }]) {
        const value = item.value ? item.value : item;
        // Save value in store
        // Default get value to 'get' or 'data'
        // if 'dataIsPriority' get value to 'data' or 'get'
        this.$helpers.setParametersStore.setValueStore(el, value.replace(/(_)/g, '-'), value,
          `${partnerStore}SET_${value.toUpperCase()}`, !item.notCookie ? value : '',
          !!item.dataIsPriority);
      }
      for (const item of ['ga-id', 'ga-deactivate', {
        value: 'initial-data',
        store: partnerStore,
      }, {
        value: 'custom-id',
        store: partnerStore,
      }]) {
        const value = item.value ? item.value : item;
        this.$helpers.setParametersStore.setDataValueStore(el, value, `${item.store || analyticsStore}SET_${value.replace(/(-)/g, '_')
          .toUpperCase()}`);
      }
    }
    if (window.nxtConfigLocal) {
      this.$store.commit(`${partnerStore}SET_PARTNER_CONFIG`, { config: window.nxtConfigLocal });
    }
  },
  store,
  i18n,
  render: (h) => h(App),
}).$mount('#nxt-lead-form');
