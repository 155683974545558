<template>
  <step-manager v-if="currentStep" :class="$setClasses">
    <input-fields :class="$vClass('fields')"
                  :fields="currentStep.fields" :step="currentStep"></input-fields>
  </step-manager>
</template>

<script>
import { mapGetters } from 'vuex';
import InputFields from '@/organisms/InputFields.vue';
import StepManager from '@/organisms/StepManager.vue';

export default {
  name: 'multi-step-form',
  nameClassStyle: 'multi-step-form',
  components: {
    StepManager,
    InputFields,
  },
  computed: {
    ...mapGetters({
      currentStep: 'partnerStore/currentStep',
    }),
  },
};
</script>
