const context = require.context('./', true, /\.js/);
const modules = {};

context.keys().forEach((path) => {
  const name = path.split('/')[1].replace('.js', '');
  if (name !== 'index') {
    modules[name] = context(path).default;
  }
});

export default modules;
