import Vuex from 'vuex';
import Vue from 'vue';
import modulesBase from '@nextories/vue-core-components/src/store/modules';
import modules from './modules';

Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    ...modulesBase,
    ...modules,
  },
  strict: false,
});
