<template>
  <layout ref="layout"
          :active-item="activeItemNumber"
          :child-route="displaySubtitle"
          :class="$setClasses"
          :display-nav-header="displayNavHeader"
          :items="headerSteps"
          :parent-route="parentRoute"
          :title="$t('base.step.title')"
          :subtitle="displaySubtitle"
          :total-routes="totalRoutes"
          display-current-index
          display-progress
          hide-action-bar>
    <transition :name="$vPrefix('fade')" mode="out-in">
      <div v-if="!isLoading" :key="currentStepNb"
           :class="[$vClass('body'),
      {[$vPrefix('--is-panel-right')]: currentStep.right_panel_content}]"
           :id="`${currentStep.id}-step`">
        <panel v-if="currentStep.left_panel_content"
               :class="$vClass('left-panel')"
               :content="currentStep.left_panel_content" />
        <ValidationObserver ref="form"
                            :class="$vClass('content')">
          <form id="form"
                :class="$vClass('content__form')"
                @submit.prevent="submitForm()">
            <slot />
          </form>
          <nxt-text-content v-if="messageError"
                            :class="[$vClass('content__error'), $vPrefix('text--error')]"
                            tag="div">
            {{ messageError }}
          </nxt-text-content>
        </ValidationObserver>
        <div ref="action-bar" :class="$vClass('action-bar')">
          <nxt-action-bar
            v-if="!(typeof currentStep.button_next === 'boolean'
             && !currentStep.button_next && !displayBackButton)"
            :class="$vClass('action-bar__content')"
            :display-back-button="displayBackButton"
            :display-next-button="displayNextButton"
            :show-next="!isLastStepForm"
            :text-back-button="$t('base.back')"
            :text-next-button="currentStep && currentStep.text_button_next || $t('base.next')"
            :wait-show-next="!!isLastStepForm"
            type-next-button="submit"
            @back="backStep"
          />
        </div>
        <panel v-if="currentStep.right_panel_content"
               :class="$vClass('right-panel')"
               :content="currentStep.right_panel_content" />
      </div>
      <div v-else>
        <nxt-loader sizeLoader="--size-small" />
      </div>
    </transition>
  </layout>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import Layout from '@/templates/Layout.vue';
import Panel from '@/organisms/Panel.vue';

export default {
  name: 'step-manager',
  nameClassStyle: 'step-manager',
  components: {
    Layout,
    Panel,
  },
  computed: {
    ...mapGetters({
      scrollFields: 'partnerStore/scrollFields',
      rooms: 'furnituresStore/rooms',
      currentStepNb: 'partnerStore/currentStepNb',
      projectToken: 'partnerStore/projectToken',
      typeMeeting: 'partnerStore/typeMeeting',
      totalSteps: 'partnerStore/totalSteps',
      allowEstimate: 'partnerStore/allowEstimate',
      isDataSaving: 'partnerStore/isDataSaving',
      fixedHeaderHeight: 'partnerStore/fixedHeaderHeight',
      steps: 'partnerStore/steps',
      onlineBookingLoading: 'partnerStore/onlineBookingLoading',
      dataSaveError: 'partnerStore/dataSaveError',
      messageError: 'partnerStore/messageError',
      currentStep: 'partnerStore/currentStep',
      scrollYAuto: 'partnerStore/scrollYAuto',
    }),
    formattedRooms() {
      const rooms = {};
      for (const room of this.rooms) {
        if (room.meubles && room.meubles.length) {
          rooms[room.name] = room.meubles.map((meuble) => ({
            name: meuble.name,
            volume: meuble.volume,
            count: meuble.quantity,
          }));
        }
      }
      return rooms;
    },
    isLoading() {
      return this.isDataSaving || this.onlineBookingLoading;
    },
    displaySubtitle() {
      return (this.currentStep && this.currentStep.display_name) || '';
    },
    displayBackButton() {
      return this.currentStepNb > 0;
    },
    parentRoute() {
      let previewStepIndex = this.currentStepNb - 1;
      if (this.currentStep && this.currentStep.previous_step) {
        const findIndexStep = this.steps.findIndex((step) => step.id
          === this.currentStep.previous_step);
        if (findIndexStep !== 0) {
          previewStepIndex = findIndexStep;
        }
      }
      return (this.steps[previewStepIndex] && this.steps[previewStepIndex].display_name) || '';
    },
    totalRoutes() {
      return this.headerSteps.length;
    },
    displayNavHeader() {
      return !this.currentStep.hide_header;
    },
    activeItemNumber() {
      return this.currentStep.display_number
        ? this.currentStep.display_number - 1
        : this.currentStepNb;
    },
    isLastStepForm() {
      return this.currentStepNb + 1 === this.totalSteps;
    },
    headerSteps() {
      return this.steps.filter((step) => !step.hide_header);
    },
  },
  data() {
    return {
      displayNextButton: true,
    };
  },
  created() {
    this.$helpers['event-bus'].$on('scrollField', ({
      el,
      behavior,
      space,
    }) => this.scrollFields && this.scrollElement(el, behavior, space));
    this.$helpers['event-bus'].$on('validateForm', (data) => {
      if (data) {
        this.submitForm(data.action);
      } else if (this.$refs && this.$refs.form) this.$refs.form.validate();
    });
    this.$helpers['event-bus'].$on('showNextButton', () => {
      if (this.currentStep && this.currentStep.button_next
        && this.currentStep.button_next.ref_fields
        && this.currentStep.button_next.ref_fields.length) {
        this.$set(this.currentStep.button_next, 'value',
          this.$helpers.fieldUtils
            .isValidRefFields(this.currentStep.button_next.ref_fields, [this.currentStep]));
        this.displayNextButton = !!this.showNextButton();
      }
    });
    this.displayNextButton = this.showNextButton();
  },
  methods: {
    ...mapActions({
      postProject: 'partnerStore/postProject',
      updateProject: 'partnerStore/updateProject',
      getEstimation: 'partnerStore/getEstimation',
      getOnlineBooking: 'partnerStore/getOnlineBooking',
      postAllowEstimate: 'partnerStore/postAllowEstimate',
      postCalcMinarmRights: 'partnerStore/postCalcMinarmRights',
    }),
    async submitForm(action) {
      this.$set(this.currentStep, 'formSubmitted', true);
      const isValid = this.$refs && this.$refs.form && await this.$refs.form.validate();
      if (!isValid) {
        const inputsContainer = document.getElementsByClassName('is-invalid');
        if (inputsContainer && inputsContainer[0]) {
          const input = inputsContainer[0];
          const inputRect = input.getBoundingClientRect();
          if (inputRect.top < 0) {
            window.scroll({
              top: inputRect.top + window.scrollY - this.fixedHeaderHeight - 60,
              behavior: 'smooth',
            });
          }
          // Move focus on to the first input where there is error
          const elInput = inputsContainer[0].getElementsByClassName('el-input__inner');
          if (elInput && elInput[0]) {
            elInput[0].focus();
          }
        }
      } else {
        this.actionStep(action);
      }
    },
    showNextButton() {
      const button = this.currentStep && this.currentStep.button_next;
      return !!((typeof button !== 'boolean' && typeof button !== 'object')
        || ((typeof button === 'boolean' && button) || button.value));
    },
    backStep() {
      this.$set(this.currentStep, 'readyForAutoScroll', false);
      this.$store.commit('partnerStore/SET_ACTIVE_STEP',
        {
          currentStepNb: this.currentStepNb - 1,
          nextStep: this.currentStep && this.currentStep.previous_step,
        });
    },
    actionStep(action) {
      action = action || this.currentStep.action;
      if (action && this[action]) {
        this[action]();
        return;
      }
      this.nextStep();
    },
    get_estimation() {
      this.getEstimation(this.cleandData());
    },
    save_data() {
      const typeMethod = this.projectToken ? this.updateProject : this.postProject;
      typeMethod(this.cleandData());
    },
    check_allow_estimate() {
      this.postAllowEstimate(this.$helpers.fieldUtils.cleanData([this.currentStep]));
    },
    calc_minarm_rights() {
      this.postCalcMinarmRights(this.cleandData());
    },
    nextStep(nextStep) {
      this.$set(this.currentStep, 'readyForAutoScroll', false);
      this.$store.commit('partnerStore/SET_ACTIVE_STEP',
        {
          currentStepNb: this.currentStepNb + 1,
          nextStep: nextStep || (this.currentStep && this.currentStep.next_step),
        });
    },
    scrollStep(top) {
      if (top) {
        if (this.$refs.layout && this.$refs.layout.$refs['nav-header']) {
          const navHeader = this.$refs.layout.$refs['nav-header'].getBoundingClientRect();
          if (navHeader.top < 0) {
            this.scrollWithBehavior(
              navHeader.top + window.pageYOffset - this.fixedHeaderHeight,
              'auto',
            );
          }
        }
        return;
      }
      if (this.$refs && this.$refs['action-bar']) {
        this.scrollElement(this.$refs['action-bar']);
      }
    },
    scrollWithBehavior(scrollTo, behavior) {
      const html = document.querySelector('html');
      const previousBehavior = html.style.scrollBehavior;
      html.style.scrollBehavior = behavior;
      window.scroll({
        top: scrollTo,
        behavior,
      });
      html.style.scrollBehavior = previousBehavior;
    },
    scrollElement(el, behavior, space) {
      if (el) {
        const elementRect = el.getBoundingClientRect();
        if (elementRect) {
          this.scrollWithBehavior(
            elementRect.top + el.ownerDocument.defaultView.pageYOffset
            - window.innerHeight + elementRect.height + (space || 0),
            behavior || 'auto',
          );
        }
      }
    },
    cleandData() {
      const data = this.$helpers.fieldUtils.cleanData(this.steps);
      if (data.formData && Object.keys(this.formattedRooms).length) {
        data.formData.meubles = this.formattedRooms;
      }
      return data;
    },
  },
  watch: {
    isDataSaving(value) {
      if (value) {
        setTimeout(() => this.scrollStep(true), 300);
      }
      if (!value && !this.dataSaveError) {
        let nextStep;
        const findTypeMeting = this.currentStep
          && this.currentStep.fields.find((field) => field.type === 'type-meeting');
        if (findTypeMeting) {
          if (this.typeMeeting && this.typeMeeting.value === 'ONLINE') {
            this.getOnlineBooking();
            return;
          }
          nextStep = this.typeMeeting.next_step;
        }
        this.nextStep(nextStep);
      }
    },
    currentStepNb(nb, old) {
      this.displayNextButton = this.showNextButton();
      if (this.scrollYAuto) {
        setTimeout(() => this.scrollStep(nb > old), nb > old ? 300 : 400);
      }
      this.$nextTick(() => {
        setTimeout(() => {
          this.$set(this.currentStep, 'readyForAutoScroll', true);
        }, 500);
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.#{$prefixName}step-manager {
  &__body {
    padding: 0;
    @include breakpoint(tablet) {
      display: grid;
      column-gap: var(--nxt-space-large);
      grid-template-columns: auto 1fr auto;
      grid-template-rows: auto auto 1fr;
      grid-template-areas:
      "loader loader loader"
      "left-panel content right-panel"
      "left-panel action-bar right-panel";
    }
    @include breakpoint(tablet) {
      :deep(.#{$prefixName}inventory) {
        &__header .#{$prefixName}inline-selector__list {
          max-width: calc(100vw - 60px - 125px);
        }

        .#{$prefixName}furniture-selector .#{$prefixName}inline-selector__list {
          max-width: calc(100vw - 130px);
        }
      }
    }

    &.#{$prefixName}--is-panel-right {
      @include breakpoint(tablet) {
        :deep(.#{$prefixName}inventory) {
          &__header .#{$prefixName}inline-selector__list {
            max-width: calc(65vw - 60px - 125px);
          }

          .#{$prefixName}furniture-selector .#{$prefixName}inline-selector__list {
            max-width: calc(65vw - 130px);
          }
        }
      }
    }
  }

  &__left-panel {
    grid-area: left-panel;
    font-size: var(--nxt-font-size-medium);
    @include breakpoint(tablet) {
      margin-left: var(--nxt-space-large);
    }
  }

  &__content {
    grid-area: content;

    &__form {
      margin: auto;
    }
  }

  &__right-panel {
    grid-area: right-panel;
    font-size: var(--nxt-font-size-medium);
    border-bottom-right-radius: 10px;
    border-bottom-left-radius: 10px;
    @include breakpoint(tablet) {
      border-bottom-left-radius: 0;
    }
  }

  &__action-bar {
    grid-area: action-bar;
    width: 100%;
  }

  &__loader {
    grid-area: loader;
  }

  &__content__form, &__action-bar__content {
    padding: var(--nxt-space-large) 10px;
    @include breakpoint(tablet) {
      margin: auto;
    }
  }

  &__content__error {
    margin: 0;
    padding: 0 10px;
  }
}

.#{$prefixName}fade {
  &-enter-active, &-leave-active {
    transition: opacity 300ms;
  }

  &-enter, &-leave-to {
    opacity: 0;
  }
}
</style>
