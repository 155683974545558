<template>
  <div :class="$setClasses">
    <input-label
      v-if="label"
      :class="$vPrefix('input__label')"
      :input-id="inputId"
      :text="label"></input-label>
    <slot></slot>
  </div>
</template>
<script>
import Field from '@nextories/vue-core-components/src/components/molecules/Field.vue';

export default {
  extends: Field,
};
</script>

<style lang="scss" scoped>
.#{$prefixName}field {
  width: 100%;
  max-width: var(--nxt-content-width-small);

  &.--direction-row {
    display: flex;

    .#{$prefixName}input-label {
      font-size: var(--nxt-font-size-large);
      padding-right: var(--nxt-space-default);
    }
  }

  &.--padding-top {
    padding-top: var(--nxt-space-default);
  }

  @include breakpoint(desktop) {
    max-width: var(--nxt-content-width-default);
  }
}
</style>
