import elementUI from '@nextories/vue-core-components/src/utils/modules/elementUI';
import {
  Step,
  Steps,
} from 'element-ui';
import CollapseTransition from 'element-ui/lib/transitions/collapse-transition';

export default {
  components() {
    return [Step, Steps];
  },
  setup(Vue) {
    elementUI.setup(Vue, this.components());
    Vue.component(CollapseTransition.name, CollapseTransition);
  },
};
