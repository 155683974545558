export default {
  subtitle: 'Votre rendez-vous devrait durer environ {duration} min. Sélectionnez un créneau disponible pour planifier votre rendez-vous',
  calendarOptions: {
    daysLabel: ['Lundi', 'Mardi', 'Mercredi', 'Jeudi', 'Vendredi', 'Samedi', 'Dimanche'],
    monthsLabel: ['Jan', 'Fev', 'Mar', 'Avr', 'Mai', 'Juin', 'Juil', 'Aoû', 'Sep', 'Oct', 'Nov', 'Dec'],
    backgroundColors: '#CEEBE9',
    loadingText: 'Chargement',
    emptyDate: 'Pas de dates pour cette semaine',
  },
  wish: 'Vous souhaitez réserver un rendez-vous le : ',
  infoDate: '{date} de {start} à {end}',
  booking: 'Réserver ce créneau',
  confirmDate: '<div class="nxt-confirm__meeting__calendar__content__item"><div class="nxt-confirm__meeting__calendar__content__item__month">{month}</div><div class="nxt-confirm__meeting__calendar__content__item__day">{day}</div></div><div class="nxt-confirm__meeting__calendar__content__item">{weekday} {day} {month}<br>de {hoursStart} à {hoursEnd}</div>',
};
