import base from '@nextories/vue-core-components/src/i18n/langs/fr/base';

export default {
  ...base,
  yes: 'Oui',
  no: 'Non',
  loading: 'Chargement en cours...',
  delete: 'Supprimer',
  cancel: 'Annuler',
  valid: 'Valider',
  next: 'Suivant',
  back: '< Retour',
  close: 'Fermer',
  phone: {
    text: '01 82 52 10 12',
    link: 'tel:+33182521012',
  },
  contactAdmin: 'Veuillez contacter un administrateur.',
  messageError: {
    form: 'Une erreur est survenue pendant le chargement du formulaire.',
    calculateDistance: 'Une erreur est survenue pendant le calcul de la distance.',
    slotAvailable: 'Les créneaux de réservation sont temporairement indisponibles.',
    updateTypeMeeting: 'Une erreur est survenue pendant la sauvegarde du type de rendez-vous.',
    saveProject: 'Une erreur est survenue pendant la sauvegarde de vos données.',
    postMeeting: 'Une erreur est survenue pendant la réservation de votre rendez-vous.',
    getTypeMeeting: 'Les types de rendez-vous sont temporairement indisponibles.',
    getEstimate: 'Une erreur est survenue pendant le calcul de votre estimation.',
    onlineBooking: 'Une erreur est survenue pendant la redirection vers le formulaire.',
    minarmRights: 'Une erreur est survenue pendant le calcul de votre plafond financier.',
  },
  ignoreStep: 'Ignorer cette étape >',
  calcVolume: 'Calculer votre volume',
  timeZone: 'fr-FR',
  required: 'Le champ {_field_} est obligatoire',
  regex: 'Le champ {_field_} est invalide',
  validation: {
    address: {
      regex: 'Le champ {_field_} n\'est pas valide',
    },
    date: {
      regex: 'Veuillez renseigner une date valide',
    },
    excludeValues: 'Cette valeur n\'est pas autorisée',
  },
  trustpilot: {
    title: 'NOTRE PRIORITÉ ? VOTRE SATISFACTION',
  },
  step: {
    title: 'ÉVALUATION DE VOTRE BESOIN',
  },
};
