<template>
  <ValidationProvider v-slot="{ failedRules, classes }"
                      :class="$setClasses"
                      :name="field.short_name || field.display_name || ' '"
                      :rules="rulesInput"
                      :vid="field.name || field.id"
                      ref="provider-primary"
                      tag="div">
    <nxt-field :class="field.required && '--required'"
               :input-id="inputId"
               :label="field.display_name"
               :placeholder="field.placeholder"
               :popover="field.display_name && field.popover">
      <component v-model="field.value"
                 :accessibility="field.accessibility"
                 :after-content="field.after_content || defaultAfterContent"
                 :autocomplete="field.autocomplete"
                 :before-content="field.before_content || defaultBeforeContent"
                 :button-next="field.button_next"
                 :class="['el-input', !!(!field.display_name && field.popover) ? 'is-popover' : '',
            !isDecomposedInput && classes['is-valid'] && 'is-valid',
            displayErrors && !isDecomposedInput && classes['is-invalid'] && 'is-invalid']"
                 :clearable="field.clearable"
                 :convert-to-date="field.convert_to_date"
                 :country="field.country"
                 :debounce="field.debounce"
                 :disabled="field.disabled"
                 :display-buttons="field.display_buttons"
                 :display-country="field.display_country"
                 :display-errors="displayErrors"
                 :display-input="field.display_input"
                 :field="field"
                 :height="field.height"
                 :input-id="field.name || field.id"
                 :is="inputName"
                 :is-known-address="field.autocomplete"
                 :key="field.name || field.id"
                 :label="!field.display_name ? field.label || field.placeholder : undefined"
                 :lock-options="field.lock_options"
                 :marks="field.marks"
                 :max="field.max"
                 :min="field.min"
                 :multiple="field.multiple"
                 :force-surface-to-volume="field.force_surface_to_volume"
                 :options="field.options"
                 :other-input="field.other_input"
                 :placeholder="field.placeholder || field.display_name"
                 :placeholder-end="field.placeholderEnd"
                 :placeholder-start="field.placeholderStart"
                 :popover="!field.display_name && field.popover"
                 :popper-append-to-body="false"
                 :prepend="field.prepend"
                 :range="!!field.range"
                 :ref-models="field.ref_models"
                 :required="field.required"
                 :show-steps="!!field.show_steps"
                 :show-stops="!!field.show_stops"
                 :show-tooltip="!!field.show_tooltip"
                 :step="field.step"
                 :suffix="field.suffix"
                 :type="field.type"
                 :value-key="field.value_key"
                 :vertical="!!field.vertical"
                 :width="field.width"
                 option-label="display_name"
                 ref="el-input"
                 @changedValue="nextFields(true)"
                 @onBlur="onBlur" />
    </nxt-field>
    <ul v-if="displayErrors && !isDecomposedInput && Object.keys(failedRules).length"
        :class="[$vClass('list')]">
      <li v-for="(error, index) in failedRules"
          :key="`error-${index}`">
        <nxt-text-content :class="[$vPrefix('text--error'), classes]"
                          tag="span">
          {{ generateError(index, error) }}
        </nxt-text-content>
      </li>
    </ul>
  </ValidationProvider>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  name: 'input-container',
  nameClassStyle: 'input-container',
  props: {
    field: Object,
    currentStep: Object,
    scrollField: Boolean,
    indexField: Number,
  },
  computed: {
    ...mapGetters({
      scrollYAuto: 'partnerStore/scrollYAuto',
      scrollFields: 'partnerStore/scrollFields',
    }),
    displayErrors() {
      return this.field.displayFieldError || this.currentStep.formSubmitted;
    },
    isDecomposedInput() {
      return this.field.type === 'address' && !this.field.autocomplete;
    },
    rulesInput() {
      const rules = {};
      if (this.field.required) {
        rules.required = this.field.required;
      }
      if (this.field.type === 'address') {
        rules.address = true;
      } else if (this.field.type === 'date') {
        rules.date = true;
      } else if (this.field.type === 'email') {
        rules.email = true;
      }
      if (this.field.exclude_values && this.field.exclude_values.length) {
        // eslint-disable-next-line dot-notation
        rules['exclude_values'] = this.field.exclude_values;
      }
      if (this.field.pattern) {
        rules.regex = this.field.pattern;
      }
      return rules;
    },
    inputName() {
      if (this.field.pathComponent) {
        if (this.field.pathComponent.startsWith('src/components')) {
          return () => import(`@nextories/vue-core-components/${this.field.pathComponent}.vue`);
        }
        return () => import(`@/${this.field.pathComponent}.vue`);
      }
      const fieldType = this.field.type.replace(/-[a-z]/g, ($) => $[1].toUpperCase())
        .replace(this.field.type.charAt(0), ($) => $.toUpperCase());
      switch (fieldType) {
      case 'Address':
        this.$set(this.field, 'pathComponent', 'molecules/address/Address');
        return () => import(`@/${this.field.pathComponent}.vue`);
      case 'Estimate':
      case 'EstimationSimulator':
      case 'Volume':
      case 'Meeting':
      case 'Card':
      case 'Rooms':
      case 'Inventory':
      case 'Confirm':
      case 'TypeMeeting':
      case 'HtmlContent':
      case 'MinarmRights':
      case 'ScriptContent':
        this.$set(this.field, 'pathComponent', `organisms/fields/${fieldType}`);
        return () => import(`@/${this.field.pathComponent}.vue`);
      default: {
        this.$set(this.field, 'pathComponent',
          `src/components/atoms/input/Input${((fieldType === 'Email' || fieldType === 'Tel') && 'Text') || fieldType}`);
        return () => import(`@nextories/vue-core-components/${this.field.pathComponent}.vue`);
      }
      }
    },
  },
  data() {
    return {
      defaultAfterContent: undefined,
      defaultBeforeContent: undefined,
      inputId: undefined,
    };
  },
  created() {
    if (this.field && this.field.type) {
      if (['text', 'number', 'address', 'textarea', 'date', 'select', 'tel', 'email']
        .find((input) => input === this.field.type)) {
        this.inputId = this.field.name || this.field.id;
      } else if (this.field.type === 'estimation-simulator') {
        this.defaultAfterContent = this.$t('estimate.simulator.afterContent');
        this.defaultBeforeContent = this.$t('estimate.simulator.beforeContent');
      }
    }
    this.$set(this.field, 'displayFieldError', false);
    this.$set(this.field, 'visibility', true);
    // eslint-disable-next-line no-prototype-builtins
    if (!this.field.hasOwnProperty('value')) {
      this.$set(this.field, 'value', '');
      // Set key 'untouched' when value is not set
      // for control of data sent to api
      // when key 'value' is undefined
      this.$set(this.field, 'notTouched', true);
    }
    if (this.scrollYAuto && this.currentStep && this.currentStep.readyForAutoScroll) {
      this.$nextTick(() => {
        const actionBar = this.$root.$el.getElementsByClassName(this.$vPrefix('action-bar'));
        const space = 20 + ((actionBar && actionBar[0] && actionBar[0].scrollHeight) || 92);
        if (!this.isInViewport(this.$el, space) && this.scrollField) {
          setTimeout(() => this.$helpers['event-bus'].$emit('scrollField', {
            el: this.$el,
            behavior: 'smooth',
            space,
          }), 30);
        }
      });
    }
    setTimeout(() => {
      if (this.field && (this.field.initialValue
        || (this.field.value && this.field.value.length))) {
        this.nextFields();
      }
    }, 500);
  },
  async mounted() {
    if (this.displayErrors) {
      await this.$refs['provider-primary'].validate(this.field.value);
    }
  },
  methods: {
    isInViewport(el, space = 0) {
      const rect = el.getBoundingClientRect();
      return rect.top >= 0
        && (rect.bottom + el.scrollHeight + space)
        <= (window.innerHeight || document.documentElement.clientHeight);
    },
    nextFields(scrollField) {
      if (!this.scrollFields) {
        this.$store.commit('partnerStore/SET_SCROLL_FIELDS', scrollField);
      }
      if (!this.$helpers.fieldUtils.isErrorField(this.field)) {
        this.$emit('nextFields', scrollField, this.indexField + 1);
      }
    },
    generateError(key, message) {
      return (this.field.errors && this.field.errors[key]) || message;
    },
    onBlur() {
      if (this.field.value) {
        this.$set(this.field, 'displayFieldError', true);
      }
    },
  },
  watch: {
    'field.value': {
      deep: true,
      handler() {
        // eslint-disable-next-line no-prototype-builtins
        if (this.field.hasOwnProperty('notTouched') && this.field.value) {
          // Delete key 'not touched' when user first changed value
          delete this.field.notTouched;
        }
        if (this.field.type === 'number' || this.field.type === 'volume'
          || this.field.type === 'input-number' || this.field.type === 'slider') {
          this.nextFields();
        }
        if (this.field.type === 'address') {
          this.nextFields(true);
          this.$refs['provider-primary'].syncValue(this.field.value);
          if (this.field.autocomplete) {
            this.onBlur();
          }
        }
        this.$helpers['event-bus'].$emit('showNextButton', { field: this.field });
      },
    },
  },
};
</script>

<style lang="scss" scoped>
.#{$prefixName}input-container {
  &.--display-none {
    display: none;
  }

  &__list {
    list-style-type: none;
    padding: var(--nxt-space-small) 0 0;
    margin: 10px 0;
  }

  :deep(.el-input) {
    &.is-valid {
      input {
        border-color: var(--nxt-color-primary);
      }
    }

    &.is-invalid {
      input {
        border-color: var(--nxt-color-danger);
      }
    }

    .#{$prefixName}auto-complete-address__decomposed__item {
      border-color: var(--nxt-color-ghost);

      &.is-invalid {
        input {
          border-color: var(--nxt-color-ghost);
        }
      }

      &.is-error input {
        border-color: var(--nxt-color-danger);
      }

      &.is-valid {
        input {
          border-color: var(--nxt-color-success);
        }
      }
    }
  }

  :deep(.#{$prefixName}text.is-invalid) {
    color: var(--nxt-color-danger);
  }
}
</style>
