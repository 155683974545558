const context = require.context('./', true, /\.js/);
const helpers = {};

context.keys().forEach((path) => {
  const name = path.split('/')[1].replace('.js', '');
  if (name !== 'index') {
    helpers[name] = context(path).default;
  }
});

export default helpers;
